import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateService } from "./translate.service";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {MatExpansionModule} from '@angular/material/expansion';
import { LOCALE_ID } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import km from '@angular/common/locales/km';
import { MatTabsModule } from '@angular/material';



import {
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatMenuModule,
  MatGridListModule,
  MatCardModule,
  MatPaginatorModule,
  MatDialogModule,
  MatButtonToggleModule,
  MatFormFieldModule,
  MatTableModule,
  MatInputModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTooltipModule
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToolsComponent, ToolDetail } from "./tools/tools.component";
import { from } from "rxjs";
import { HomeComponent, NewsDetail } from "./home/home.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule, AngularFireAuth } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { ItComponent } from "./it/it.component";
import { DocsComponent } from "./docs/docs.component";
import { RouterModule } from "@angular/router";
import { MasterComponent } from "./master/master.component";
import { NewsComponent, Detail } from "./news/news.component";
import { FooterComponent } from "./footer/footer.component";
import { TranslatePipe } from "./translate.pipe";
import { LawComponent } from "./law/law.component";
// import { DetailComponent } from './detail/detail.component';
import { ReadDetailsComponent } from './read-details/read-details.component';
import { VideoComponent, PlayVideo } from './video/video.component';
import { I18nModule } from './i18n/i18n.module';
  import { DeviceDetectorModule } from 'ngx-device-detector';
// AoT requires an exported function for factories
//Ling
import {swaggerApiService} from './services/swaggerApi.service';
import {SharedDataService} from './services/sharedDataService';
import { SafePipe } from "./safe.pipe";
import { NotFoundComponent } from './not-found/not-found.component';
import { PdfDocsComponent } from './pdf-docs/pdf-docs.component';
import { NotFoundErrorComponent } from './not-found-error/not-found-error.component';
import { LoadingComponent } from "./loading/loading.component";
import { ShimmerLoadingComponent } from './shimmer-loading/shimmer-loading.component';
import { NewsShimmerLoadingComponent } from './news-shimmer-loading/news-shimmer-loading.component';
import { LibraryComponent } from './library/library.component';




export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use("km");
}

@NgModule({
  declarations: [
    AppComponent,
    ToolsComponent,
    HomeComponent,
    NewsDetail,
    ItComponent,
    DocsComponent,
    ToolDetail,
    MasterComponent,
    NewsComponent,
    FooterComponent,
    TranslatePipe,
    Detail,
    LawComponent,
    // DetailComponent,
    ReadDetailsComponent,
    VideoComponent,
    PlayVideo,
    SafePipe,
    NotFoundComponent,
    PdfDocsComponent,
    NotFoundErrorComponent,
    LoadingComponent,
    ShimmerLoadingComponent,
    NewsShimmerLoadingComponent,
    LibraryComponent,
    ],
  entryComponents: [PlayVideo,NewsDetail, ToolDetail, Detail],
  imports: [
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    AngularFireAuthModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    FlexLayoutModule,
    MatCarouselModule,
    NgbModule,
    MatGridListModule,
    MatCardModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonToggleModule,
    HttpClientModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule,
    MatSortModule,
    MatProgressSpinnerModule,
    RouterModule,
    AppRoutingModule,
    MatExpansionModule,
    I18nModule,
    DeviceDetectorModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTooltipModule
   
  ],
  providers: [
    TranslateService,
    {
    
      provide:APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
       
    },
    swaggerApiService,
    SharedDataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
