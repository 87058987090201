import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NG_ASYNC_VALIDATORS } from '@angular/forms';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [DatePipe]
})



export class FooterComponent implements OnInit {
  deviceInfo = null;
  isMobile:boolean = false;
  isTablet:boolean = false;
  isDesktopDevice:boolean = false;
  myDate: any = new Date();
  @Input("lang") currentLanguage: string;
  fbLink = "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FLegalCouncilMEF&tabs&width=200&height=70&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService
  ) {
   
  }
  
  
  ngOnInit() {
  }
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  toKhmerNumber(str:any) {
    var khNumber = str.toString();

    khNumber = khNumber.replace(/0/g, '០')
    khNumber = khNumber.replace(/1/g, '១')
    khNumber = khNumber.replace(/2/g, '២')
    khNumber = khNumber.replace(/3/g, "៣")
    khNumber = khNumber.replace(/4/g, "៤")
    khNumber = khNumber.replace(/5/g, "៥")
    khNumber = khNumber.replace(/6/g, "៦")
    khNumber = khNumber.replace(/7/g, "៧")
    khNumber = khNumber.replace(/8/g, "៨")
    khNumber = khNumber.replace(/9/g, "៩")
    
    return khNumber
  }
  
  
  convertYear(year :any, number:any) {
    let yearStr = year - number;
    if (this.currentLanguage == 'km') {
      yearStr = this.toKhmerNumber(yearStr);
    }
    return yearStr;
  }


}
